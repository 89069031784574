import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import { useMutation, useQuery } from "react-query";

import React from "react";
import { Lead } from "./Lead";

function App() {
  const {
    isLoading,
    data,
    refetch: refetchLeads,
  } = useQuery("leads", async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}enquiries/unprocessed?auth=U4AzXLrkAU9cBC9W`
    );

    return data;
  });

  const approveLead = useMutation(
    ({ leadId, propertyId }) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}enquiries/${leadId}/approve?auth=U4AzXLrkAU9cBC9W`,
        { propertyId }
      );
    },
    {
      onSuccess: () => refetchLeads(),
    }
  );

  if (isLoading || approveLead.isLoading) {
    return (
      <div className="w-screen, h-screen flex items-center justify-center">
        <TailSpin width={150} height={150} />
      </div>
    );
  }

  return (
    <div className="w-full max-w-6xl mx-auto ">
      <h1 className="text-3xl font-bold">Pending Facebook Leads:</h1>
      <div className="grid grid-cols-4">
        <div className="font-semibold">Name</div>
        <div className="font-semibold">Address</div>
        <div className="font-semibold">Matched Address</div>
        <div className="font-semibold">Actions</div>
        {data.map((r) => {
          return <Lead data={r} approveLead={approveLead} key={r.id} />;
        })}
      </div>
    </div>
  );
}

export default App;
